<template>
    <div id="the-collapsible" class="d-flex-c-ast-jfs l-radius" ref="test" :class="{show : height}">
        <div class="header d-flex-ac-jsb px-15" @click="toggle">
            <h4>
                {{item.header}}
            </h4>
            <small dir="ltr" class="d-flex">
                <slot name="header-left" :show="!!height">
                    {{item.time}}
                </slot>
            </small>
        </div>
        <div class="description" :style="{height : height + 'px'}">
            <p class="p-15 m-font" ref="desc">
                {{item.desc}}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheCollapsible",
        props:['item'],
        data() {
            return {
                show: false,
                height: 0,
            }
        },
        methods: {
            toggle() {
                this.height = this.height ? 0 : this.$refs.desc.clientHeight
                for(let collapsible of this.$parent.$refs.collapsible){
                    console.log(collapsible.height)
                    collapsible.height
                }

            }
        }
    }
</script>

<style lang="scss">
    #the-collapsible {
        background-color: var(--dark);
        transition: background-color 0.3s;
        text-align: right;

        &.show{
            background-color: var(--semiDark);
        }

        .header {
            height: 65px;
            cursor: pointer;

            small {
                opacity: 0.5;
            }

            h4 {
                padding-right: 20px;
                position: relative;

                &:before {
                    content: '•';
                    font-size: 30px;
                    color: var(--yellow);
                    position: absolute;
                    right: 0;
                    top: 65%;
                    line-height: 0;
                }
            }
        }

        .description {
            overflow: hidden;
            height: 0;
            transition: height 0.3s;
        }

        &.deactive{
            .header {
                h4 {
                    opacity: 0.5;
                    &:before {
                        color: var(--white);
                    }
                }
            }
        }
    }
</style>